import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import _ from 'lodash';
import About from '../components/about';
import Service from '../components/service';
import Solution from '../components/solution';
import BlogList from '../components/bloglist'
import Seo from '../components/seo';
import Parts from '../components/parts';
import Contact from '../components/contact';
import TextBlock from '../components/textblock';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';
import HeroBanner from '../components/HeroBanner/herobanner';
//import Helmet from "react-helmet"



export default function IndexPage({ data }) {

  let siteData = _.merge( _.pick(data.siteMetadata.siteMetadata,['description','title','logo','siteUrl','posturl','captchaurl','tagLine','cookieDebug']),
                          _.pick(data.global,['id','company','address','duns','email','tel','twitter','web','tagLine']), _.pick(data.global.body.data,['body'] ),
                  )
  let pageData = _.merge( _.pick(data.page.nodes[0],['id','description','title','slug','section','page','date','keywords']),
                          _.pick(data.page.nodes[0].body.data,['body']) )

  let servicespageData = _.merge( _.pick(data.servicespage.nodes[0],['id','description','title','slug','section','page','date']),
                          _.pick(data.servicespage.nodes[0].body.data,['body']) )

  let solutionspageData = _.merge( _.pick(data.solutionspage.nodes[0],['id','description','title','slug','section','page','date']),
                          _.pick(data.solutionspage.nodes[0].body.data,['body']) )

  let partsData = _.orderBy( data.page.nodes[0].parts.map( item => {
                //console.debug( item );
                return _.merge( _.pick( item, ['id','title','order','page','layouttype','partId','anchor','options']),
                                { image: (item.image) ? item.image.localFile : null },
                                { text: (item.text && item.text.data ) ? ( item.text.data.text ) : "" })
              }),["order"],["asc"]  )
  //console.debug( pageData, data.page.nodes, data.page.nodes[0].body.data )
  const textOptions = { margin: true, centre: false, centretitle: true, titlecapitalize: true }
  //const webChat = true
  //<Helmet><script src="https://embed.acornchat.com/a/0cde998c-77d8-402e-ba09-0315f91b09d2.js"></script></Helmet>
//               <Contact key="Contact" ></Contact>
  return (
      <Layout header="home" >

               <Seo  title={pageData.title}  keywords={pageData.keywords} description={pageData.description}/>
               <CookieConsent location="bottom"  buttonText="Yes, I Agree" declineButtonText="Decline"  cookieName="Viewdeck-CookieConsent"
                 style={{ background: "#2B373B" }}  buttonStyle={{ color: "#4e503b", fontSize: "13px" }} expires={150} debug={siteData.cookieDebug}
                 >
                 We use cookies to improve your experience on our site and to show you relevant information. This is further detailed in our{" "}
                 <Link to="/cookie-policy"><span>Cookie Policy</span></Link>{" "}and{" "} <Link to="/cookie-policy"><span>Privacy Policy</span></Link>.{" "}
               </CookieConsent>

               <HeroBanner/>

               <TextBlock title={pageData.description} mdstring={pageData.body} section="Index" options={ textOptions }/>

               <Parts key="Parts" anchor="parts" parts={partsData}/>

               <Solution  key="Solution" description={solutionspageData.description} header={false} link={true}></Solution>

               <Service  key="Service" description={servicespageData.description} header={true} link={true}></Service>

               <BlogList key="Blogs" blogsMaxCount={5} moreBlogsButton={true} ></BlogList>

               <About key="About" ></About>

      </Layout>
  )
}


//******************************************************************************


export const query = graphql`
   query {

     global: strapiGlobal {
       id
       company
       address
       duns
       email
       tel
       twitter
       web
       tagLine
       body {
         data {
           body
         }
       }
     }

     page: allStrapiPage(filter: {title: {eq: "Index"}}) {
       nodes {
         id
         title
         strapi_id
         slug
         section
         page
         description
         date
         body {
           data {
             body
           }
         }
         parts {
           id
           order
           page
           title
           text {
             data {
               text
             }
           }
           layouttype
           partId
           anchor
           options {
             parallax
             height
             centre
             centretitle
             margin
           }
           image {
                     localFile {
                       publicURL
                       childImageSharp {
                         gatsbyImageData
                       }
                     }
                   }
         }
       }
     }

     siteMetadata: site(siteMetadata: {}) {
       siteMetadata {
         description
         logo
         siteUrl
         title
         posturl
         captchaurl
         tagLine
         cookieDebug
       }
     }




     servicespage: allStrapiPage(filter: {title: {eq: "Services"}}) {
       nodes {
         id
         title
         strapi_id
         slug
         section
         page
         description
         date
         body {
           data {
             body
           }
         }
       }
     }

     solutionspage: allStrapiPage(filter: {title: {eq: "Solutions"}}) {
       nodes {
         id
         title
         strapi_id
         slug
         section
         page
         description
         date
         body {
           data {
             body
           }
         }
       }
     }

  }
`
